/* Global reset to prevent horizontal scroll */
body, html {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Animation for fade-in effect */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
}

.fade-in.visible {
  animation: fadeInUp 1s forwards;
}

/* Hero Section - Fullscreen with Tinted Background */
.hero {
  background: url('C:/Users/William/swifthr-website/public/images/BannerBackground.jpg') no-repeat center center/cover;
  position: relative;
  height: 100vh; /* Fullscreen height */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1; /* Ensures the tint is above the background image */
}

.hero-content {
  position: relative;
  z-index: 2; /* Ensures the content is above the tint */
  text-align: center;
  animation: fadeInUp 1s ease-in-out; /* Hero fades in on page load */
}

.hero-logo {
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  background-image: url('C:\Users\William\swifthr-website\public\images\HomePageBanner.GIF');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}

.hero-content p {
  font-size: 32px;
  font-weight: 200;
  color: white;
}

/* Introduction section */
.introduction {
  display: flex;
  justify-content: space-around;
  padding: 50px;
  background-color: #ffffff;
  animation: fadeInUp 1s ease forwards;
  opacity: 0;
}

.intro-text {
  max-width: 500px;
  margin: 0 20px;
}

.intro-text h3 {
  color: #00B09B;
  margin-bottom: 20px;
  font-size: 22px;
}

.intro-text p {
  color: #555;
  line-height: 1.6;
  font-size: 16px;
}

/* Features Container */
.features-container {
  margin: 0 auto 60px auto; /* Bottom margin of 60px is already present */
  margin-bottom: 100px; /* Add this for extra margin at the bottom */
  text-align: center;
  color: #ffffff;
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
}


.features-container.visible {
  animation: fadeInUp 1s ease-in-out forwards;
}

.features-header {
  font-size: 24px;
  margin-bottom: 30px;
  color: #00B09B;
  font-weight: 300;
}

/* Features Cards */
.feature-cards-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

/* Feature Card Hover Effect for Desktop */
.feature-card {
  padding-top: 40px;
  padding-bottom: 20px;
  width: 250px;
  height: 400px;
  transition: all 0.5s ease;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}

.feature-card.core-hr {
  background-color: #8B93BB; /* Blueish color */
}

.feature-card.payroll {
  background-color: #95C5BA; /* Teal color */
}

.feature-card.ess {
  background-color: #F4B382; /* Orange color */
}

.feature-card.time-attendance {
  background-color: #E6927F; /* Red color */
}

.feature-card.time-attendance p {
  color: #ffffff; /* Red color */
}

.feature-card.employee {
  background-color: #a26057; /* Red color */
}

.feature-card.employee p {
  color: #f9f9f9; /* Red color */
}

.feature-icon-wrapper {
  width: 130px;
  height: 130px;
  background-color: white;
  border-radius: 50%;
  padding: 15px;
  transition: top 0.5s ease, background-color 0.5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feature-icon {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.feature-info {
  opacity: 0;
  transition: opacity 0.5s ease, top 0.5s ease;
  text-align: center;
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.feature-card:hover {
  width: 500px;
}

.feature-card:hover .feature-info {
  opacity: 1;
  top: 150px;
  bottom: 50px;
}

.feature-card:hover .feature-icon-wrapper {
  top: 10px;
  transform: translateX(-50%);
  background-color: white;
  width: 100px;
  height: 100px;
}

.feature-card h3 {
  font-size: 24px;
  color: #ffffff;
  font-weight: 300;
}

.feature-card p {
  font-size: 16px;
  color: #000000;
  font-weight: 200;
}

/* Responsive Design - Mobile View */
@media (max-width: 768px) {

  /* Hero Section adjustments */
  

  .hero-logo {
    height: 100px; /* Adjust size for smaller screens */
    margin-bottom: 20px;
  }

  .hero-content p {
    font-size: 20px; /* Adjust font size for readability */
    padding: 0 15px; /* Add padding for mobile readability */
  }

  /* Introduction Section */
  .introduction {
    flex-direction: column; /* Stack items vertically */
    padding: 20px 10px; /* Reduce padding for smaller screens */
    text-align: center;
  }

  .intro-text {
    margin-bottom: 30px;
    max-width: 100%; /* Ensure full width on smaller screens */
  }

  .intro-text h3 {
    font-size: 22px; /* Scale down heading */
  }

  .intro-text p {
    font-size: 16px; /* Adjust text size for readability */
    line-height: 1.5;
  }

  .feature-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
  }

  .feature-card {
    width: 100%; /* Full width on mobile */
    max-width: 320px; /* Set a reasonable maximum width */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    text-align: center; /* Center content inside the card */
    background-color: #f8f9fa; /* Light background color */
    position: relative;
  }

  .feature-icon-wrapper {
    width: 80px; /* Set icon width */
    height: 80px; /* Set icon height */
    margin: 0 auto 15px; /* Center icon and add space below */
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feature-card h3 {
    font-size: 18px; /* Slightly smaller font size for heading */
    margin-bottom: 10px;
    color: #333; /* Dark text color for readability */
  }

  .feature-card p {
    font-size: 14px; /* Smaller font for description */
    line-height: 1.5; /* Increased line height for readability */
    color: #666; /* Lighter text color */
  }
}


