
.about-container {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.8;
}

/* Hero Section */
.hero-about {
  background: url('C:\Users\William\swifthr-website\public\images\AboutUs.jpg') no-repeat center center/cover;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 1;
}

.hero-about-content {
  position: relative;
  z-index: 2;
}

.hero-about h1 {
  font-size: 48px;
  font-weight: 300;
  color: white;
}

.hero-about p {
  font-size: 18px;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8); /* Slightly transparent white text */
}

/* Section Styling */
section {
  margin-bottom: 80px;
}

h2 {
  font-size: 32px;
  font-weight: 300;
  color: #2D3448;
  margin-bottom: 40px;
  position: relative;
}

p {
  font-size: 18px;
  color: #555;
  max-width: 800px;
  margin: 0 auto 40px auto;
}

.team-image,
.values-image {
  width: 100%;
  height: auto;
  max-width: 900px;
  border-radius: 15px;
  margin-top: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-image:hover,
.values-image:hover {
  transform: scale(1.05); /* Subtle hover effect on images */
}

/* Icon Styling */
.about-icon {
  font-size: 50px;
  color: #00B09B;
  margin-bottom: 20px;
  animation: fadeIn 1s ease; /* Add smooth animation */
}

.list-icon {
  font-size: 20px;
  color: #00B09B;
  margin-right: 10px;
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Values Section */
.our-values {
  background-color: #f9f9f9;
  padding: 60px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.values-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.values-list {
  list-style-type: none;
  padding-left: 0;
  max-width: 600px;
}

.values-list li {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.values-list li strong {
  color: #00B09B;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .about-container {
    padding: 30px 20px;
  }

  h2 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }

  .values-content {
    flex-direction: column;
  }

  .values-image {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .team-image {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .hero-about h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 26px;
  }

  p {
    font-size: 16px;
  }

  .team-image {
    max-width: 600px;
  }

  .values-list {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .hero-about h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }

  .team-image {
    max-width: 500px;
  }

  .values-image {
    max-width: 300px;
  }
}
