.footer-section {
    background: #2D3448;
    overflow-x: hidden; /* Prevent horizontal scroll */
    margin-top: 40px;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 145px;
    gap: 20px;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

.social-links .social-icon {
    justify-content: space-between;
    transition: all 0.3s ease;
}

.social-icon:hover {
    transform: scale(1.1);
    border-radius: 100%;
    background-color: #E6813980;
}

.social-links a {
    padding: 0 30px;
    gap: 20px;
    text-decoration: none;
}

.footer-text p {
    font-weight: 200;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
}

.quicklinks {
    display: flex; 
    align-items: center;
    justify-content: space-evenly;
    padding: 0 30px;
}
.q-link {
    gap: 20px;
    list-style: none;
    transition: all 0.3s ease;
}

.q-link:hover {
    color: #E68139;
    transform: translateY(-10px);
}

.quicklinks a {
    text-decoration: none;
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
    color: rgba(255,255,255,1);
}

.footnote {
    background-color: rgba(255,255,255,1);
    width: 100%;
    align-items: center;
}

.copyright-statement {
    color: black;
    font-size: 16px;
    text-align: center;
    line-height: 36px; 
    margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
    .footer-container {
        padding: 20px 30px; /* Reduced padding for mobile */
    }

    .social-links {
        display: flex;
        flex-direction: row;
    }

    .social-links .social-icon {
        width: 50px;
        height: 50px;
    }

    .social-links a {
        padding: 0 10px;
    }

    .footer-text p {
        font-weight: 200;
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .quicklinks {
        flex-direction: column;
        align-items: center; /* Center links for mobile */
        gap: 10px;
    }

    .q-link {
        gap: 5px;
        list-style: none;
    }

    .quicklinks a {
        text-decoration: none;
        font-size: 16px;
        font-weight: 200;
        line-height: 20px;
        padding: 5px 0;
    }

    .footnote {
        text-align: center;
    }
}
