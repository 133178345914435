.modules-container {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.hero-modules {
  background: url('C:\Users\William\swifthr-website\public\images\Modules.jpg') no-repeat center center/cover;
  background-size: cover;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-modules::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 1;
}

.hero-modules-content {
  position: relative;
  z-index: 2;
}

.hero-modules h1 {
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 200;
}

.hero-modules p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8); /* Slightly lighter text */
}

.module-section {
  margin: 60px auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
}

.module-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 40px;
}

.module-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Deeper shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  position: relative;
}

.module-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.module-icon {
  font-size: 50px;
  margin-bottom: 20px;
  color: #00B09B; /* Brand color for icons */
  animation: fadeIn 1s ease; /* Smooth entrance animation */
}

.module-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.module-description {
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
}

.module-bullet-points {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.module-bullet-points li {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.module-bullet-points li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #00B09B;
  font-size: 20px;
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Responsive Design for Tablets and Mobile */
@media (max-width: 1024px) {
  .hero-modules h1 {
    font-size: 40px;
  }

  .hero-modules p {
    font-size: 16px;
  }

  .module-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .module-card {
    padding: 25px;
  }

  .module-title {
    font-size: 22px;
  }

  .module-description {
    font-size: 15px;
  }

  .module-bullet-points li {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .hero-modules {
    height: 100vh;
  }

  .hero-modules h1 {
    font-size: 36px;
  }

  .hero-modules p {
    font-size: 14px;
  }

  .module-card {
    padding: 20px;
  }

  .module-title {
    font-size: 20px;
  }

  .module-description {
    font-size: 14px;
  }

  .module-bullet-points li {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .hero-modules h1 {
    font-size: 28px;
  }

  .hero-modules p {
    font-size: 13px;
  }

  .module-card {
    padding: 15px;
  }

  .module-title {
    font-size: 18px;
  }

  .module-description {
    font-size: 13px;
  }

  .module-bullet-points li {
    font-size: 13px;
  }
}
