.hr-features-container {
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.hr-features-heading {
  font-size: 36px;
  margin-bottom: 40px;
  color: #333;
}

.hr-feature-card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns on larger screens */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.hr-feature-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.hr-feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.hr-feature-icon {
  font-size: 36px;
  color: #00B09B;
  margin-bottom: 15px;
}

.hr-feature-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.hr-feature-card p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* Adjustments for smaller screens */
@media (max-width: 1024px) {
  .hr-feature-card-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
  }
}

@media (max-width: 768px) {
  .hr-feature-card-grid {
    grid-template-columns: 1fr; /* Single column on mobile */
  }

  .hr-features-container {
    padding: 30px 10px;
  }

  .hr-features-heading {
    font-size: 28px;
  }

  .hr-feature-card {
    padding: 15px;
  }
}

/* Hero Section */
.hr-hero-features {
  background: url('C:/Users/William/swifthr-website/public/images/Features.jpg') no-repeat center center/cover;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hr-hero-features::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 1;
}

.hr-hero-features-content {
  position: relative;
  z-index: 2;
}

.hr-hero-features h1 {
  font-size: 48px;
  color: white;
  font-weight: 300;
}

@media (max-width: 768px) {
  .hr-hero-features {
    height: 100vh;
  }

  .hr-hero-features h1 {
    font-size: 32px;
  }
}
