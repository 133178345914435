/* Navbar Wrapper */
.navbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: center; /* Center the navbar */
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0); /* Semi-transparent background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0); /* Light shadow */
}

/* Navbar Content with Restricted Width */
.navbar-container {
    width: 90%;
    max-width: 1100px; /* Set a maximum width */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(45, 52, 72);
    padding: 10px 30px;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Softer shadow */
}

/* Logo */
.swiftlg {
    width: 50px;
}

/* Navigation Links */
.navlinks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.navlink {
    color: #ffffff;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 200;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.navlink:hover {
    color: #00B09B;
}

.active {
    color: #00B09B;
    text-decoration: underline;
}

/* Demo Button */
.demo-btn {
    background-color: #00B09B;
    color: white;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    padding: 0 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.demo-btn:hover {
    background-color: #009688;
}

/* Hamburger for Mobile */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    background-color: #ffffff;
    width: 25px;
    height: 3px;
    margin: 4px 0;
    transition: all 0.3s ease-in-out;
}

/* Responsive Design for Tablets (1024px and below) */
@media (max-width: 1024px) {
    .navlinks {
        display: none;
    }

    .hamburger {
        display: flex;
    }

    .navlinks.navlinksactive {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background-color: rgba(45, 52, 72, 0.8);
        padding: 20px;
        z-index: 1;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .navlink {
        margin: 10px 0;
        text-align: center;
    }

    .demo-btn {
        display: none;
    }
}
