.contact-us-container {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;
}

/* Contact Content (Make each section full width) */
.contact-content {
  display: flex;
  flex-direction: column; /* Stack the hero and contact info vertically */
  width: 100%;
}

/* Hero Section */
.hero-contact {
  background: url('C:/Users/William/swifthr-website/public/images/Contact.jpg') no-repeat center center/cover;
  height: 100vh;
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  text-align: center;
}

.hero-contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  z-index: 1;
}

.hero-contact-content {
  position: relative;
  z-index: 2;
}

.hero-contact h1 {
  font-size: 48px;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}

.hero-contact p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
}

/* Contact Info Section */
.contact-info {
  width: 100%; /* Full width */
  background-color: #f9f9f9;
  padding: 50px 40px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
}

.contact-info h2 {
  font-size: 36px;
  color: #00B09B;
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 18px;
  color: #555;
  margin-bottom: 40px;
}

/* Social Links */
.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.social-icons {
  width: 100px;
  height: 100px;
  padding: 15px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.social-icons:hover {
  transform: scale(1.1);
  cursor: pointer;
}

/* Specific Background Colors */
.twitter { background-color: #171717; }
.whatsapp { background-color: #25D366; }
.facebook { background-color: #1877F2; }
.email { background-color: #D44638; }
.linkedin { background-color: #0077B5; }

/* Map Section */
.contact-map {
  margin-top: 40px;
  width: 100%; /* Full width */
}

.contact-map iframe {
  width: 100%;
  height: 500px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-contact {
      height: 60vh;
  }

  .contact-info {
      padding: 40px 20px;
  }

  .contact-map iframe {
      height: 400px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 768px) {
  .hero-contact h1 {
      font-size: 32px;
  }

  .hero-contact p {
      font-size: 16px;
  }

  .social-icons {
      width: 50px;
      height: 50px;
  }

  .contact-map iframe {
      height: 350px; /* Adjust height for mobile */
  }
}

@media (max-width: 480px) {
  .hero-contact h1 {
      font-size: 28px;
  }

  .hero-contact p {
      font-size: 14px;
  }

  .social-icons {
      width: 45px;
      height: 45px;
  }

  .contact-map iframe {
      height: 300px; /* Adjust height for smaller mobile devices */
  }
}
