/* Hero Section */
.hero-faq {
  background: url('C:\Users\William\swifthr-website\public\images\faq-hero.jpg') no-repeat center center/cover;
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

.hero-faq::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 1;
}

.hero-faq-content {
  position: relative;
  z-index: 2;
}

.hero-faq h1 {
  font-size: 48px;
  font-weight: 200;
  color: white;
}

/* FAQ Section */
.faq-container {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}

.faq-category {
  margin-bottom: 40px;
}

.faq-category-title {
  font-size: 32px;
  color: #00B09B;
  margin-bottom: 30px;
  font-weight: 600;
}

.faq-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.faq-question {
  width: 100%;
  background-color: #f4f4f4;
  color: #333;
  text-align: left;
  padding: 20px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.faq-question:hover {
  background-color: #e9e9e9;
}

.faq-icon {
  font-size: 22px;
  color: #00B09B;
  margin-right: 10px;
}

.arrow {
  font-size: 18px;
  color: #00B09B;
}

.faq-answer {
  padding: 15px 20px;
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  display: none;
  border-left: 3px solid #00B09B;
}

.faq-answer.open {
  display: block;
  animation: fadeIn 0.3s ease;
}

.faq-question.active {
  background-color: #00B09B;
  color: white;
}

.faq-question.active .arrow,
.faq-question.active .faq-icon {
  color: white;
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Media Queries */
@media (max-width: 768px) {
  .hero-faq h1 {
    font-size: 32px;
  }

  .faq-container {
    padding: 40px 15px;
  }

  .faq-category-title {
    font-size: 28px;
  }

  .faq-question {
    font-size: 16px;
  }

  .faq-answer {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .hero-faq h1 {
    font-size: 28px;
  }

  .faq-question {
    padding: 15px;
  }

  .faq-answer {
    padding: 10px 15px;
  }
}
