/* Splash screen container */
.splash-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2D3448; /* Background color matching your theme */
    color: white;
    font-family: 'Poppins', sans-serif;
  }
  
  /* SwiftHR logo animation */
  .logo-animation {
    text-align: center;
    position: relative;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  /* SwiftHR Logo */
  .swift-logo {
    font-size: 64px;
    font-weight: 800;
    letter-spacing: 2px;
    color: #ffffff;
    animation: logoPulse 2.5s infinite;
  }
  
  /* Animate HR differently for emphasis */
  .swift-logo span {
    color: #F4B382; /* Highlighted color for HR */
  }
  
  /* Loading Dots */
  .loading-dots {
    margin-top: 20px;
  }
  
  .dot {
    height: 16px;
    width: 16px;
    margin: 0 5px;
    display: inline-block;
    background-color: #ffffff;
    border-radius: 50%;
    animation: bounce 1.2s infinite ease-in-out;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  /* Keyframes for animations */
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes logoPulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  